import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient , HttpHeaders} from '@angular/common/http';
import * as myGlobals from '../../../../src/app/global';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  data1:any;
  base_url = myGlobals.base_url;
 constructor(private http:HttpClient,private rt:Router) { }
  
    post(url,value){
      const URL=this.base_url+url;
      const httpOptions = {headers:new HttpHeaders({'Content-Type':'application/json'})}
      return this.http.post(URL,value,httpOptions)
    }
//     newPost(url,value){
//           const headers1 = new HttpHeaders();
//           headers1.append('Content-Type', 'multipart/form-data');
//           headers1.append('Accept', 'application/json');
//           headers1.append('Access-Control-Allow-Origin', '*');
            
//           return this.http.post(url,value,{headers:headers1})
// }


    // get_role_menu(role_id)
    // {
    //   const httpOptions = {headers:new HttpHeaders({'Content-Type':'application/json'})}

    //   this.http.post(this.base_url+'get_role_menu',{'role_id' : role_id},httpOptions).subscribe(res=>{
    //     this.data1=res['data']
    //     console.log();
    //     // return this.data1
    //   });
      
    //   setTimeout(()=>{
    //     return this.data1['data'];
    //   },1000)

    // }

get(url){
       const httpOptions = {headers:new HttpHeaders({'Content-Type':'application/json'})}
         return this.http.get(this.base_url+url,httpOptions)
    }
//     get_role(url,value){
//       const httpOptions = {headers:new HttpHeaders({'Content-Type':'application/json'})}

//       return this.http.post(url,value,httpOptions).map(res=>{
//         this.data1=res
//         // console.log(this.data)
//         return this.data1
//       })
// }

// getAdminDetails()
// {
//   const httpOptions = {headers:new HttpHeaders({'Content-Type':'application/json'})}
//   return this.http.post(this.base_url+'get_admin_details',{admin_id : localStorage.getItem('id')},httpOptions);
// }

// getRouteDetails(url)
// {
//   const httpOptions = {headers:new HttpHeaders({'Content-Type':'application/json'})}
//   return this.http.post(this.base_url+'get_route_details',{admin_id : localStorage.getItem('id'), url : url},httpOptions);
// }


}