import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LoginService } from '../../../../src/app/main/apps/login.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService

     */
 newArr=[]
     naviagtion()
    {    


        if(localStorage.getItem('role_type') == undefined || localStorage.getItem('role_type') == null)
        {
            return false
        }
        var datad:any;
        var role_type : any = localStorage.getItem('role_type');
            // this.AdminService.get('admin/get-all-menus').subscribe(res=>{
            //     datad=res['data']
            //     this.newArr=datad
            //     this.ngOnInit(datad);
            //     });
             this.AdminService.get('admin/get-all-menus').subscribe(res=>{
                datad=res['data']
               for (var i = 0; i < datad.length; ++i) {
                 if(datad[i]['type'] == 'collapsable'){
                    this.AdminService.post('admin/get-menus-by-parentid',{id:datad[i].id,i:i}).subscribe(ress=>{
                 datad[ress['i']]['children']=ress['data']
                });
                 }
               }
                console.log(datad)
                this.newArr=datad
                this.ngOnInit(datad);
                });
       

    }
    constructor(
                private AdminService : LoginService,

        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
     ngOnInit(nav = ''): void
    {
        // Load the navigation either from the input or from the service
        
        if(nav != '')
        {
            this.navigation = nav;
            
        }else{
            this.naviagtion();
        }

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                // this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }
}
