import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as myGlobals from './global';
import { LoginService } from './main/apps/login.service';
@Injectable()
export class AuthService {
  // get_user=myGlobals.get_user
  constructor(private myRoute: Router,private login_service: LoginService) { }
  sendToken(role_type,token: string,email:string,id:any,name:string) {
   localStorage.setItem('role_type',role_type);
   localStorage.setItem('token',token);
   localStorage.setItem('email',email);
   localStorage.setItem('id',id);
   localStorage.setItem('name',name);
  }
  getToken() {

   //  if(localStorage.getItem("token")!='' && localStorage.getItem("token")!=null && localStorage.getItem("token")!=undefined){
   //     this.login_service.get(this.get_user+'/'+localStorage.getItem('id')).subscribe(res=>{
   //       if(res['status']==true){
   //         if(res['data']['token']!=localStorage.getItem("token")){
   //            localStorage.removeItem("token");
   //            localStorage.removeItem("username");
   //            localStorage.removeItem("id");
   //            localStorage.clear();
   //         }
   //       }else{

   //         localStorage.removeItem("token");
   //         localStorage.removeItem("username");
   //         localStorage.removeItem("id");
   //         localStorage.clear();

   //       }
         
   //     })

   // }else{
   //     localStorage.removeItem("token");
   //         localStorage.removeItem("username");
   //         localStorage.removeItem("id");
   //         localStorage.clear();
   // }  

    return localStorage.getItem("token"); 
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  logout() {
    localStorage.clear();
    this.myRoute.navigate(["pages/auth/login"]);
  }
}